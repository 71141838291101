import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useLocales } from 'src/locales';

const SchedulingNote = () => {
  const { t } = useLocales();
  return (
    <Card>
      <CardContent>
        <Typography variant="subtitle1">{t('dialogs.addNewHearingCouncil.note')}:</Typography>
        <Typography sx={{ whiteSpace: 'pre-line' }} variant="caption" color="text.secondary">
          {t('dialogs.addNewHearingCouncil.schedulingNote')}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SchedulingNote;
